import { createContext, useContext, useEffect, useState } from 'react';
import { User } from '../types';
import axios from 'axios';
import {
  apiUrl,
  logoutUrl,
  returnUrlByEnvironment,
} from '../constants/urlConstants';

interface UserProps {
  user?: User;
  children: React.ReactNode;
}

export const MyContextData = createContext<any | null>({} as any);
export const useAuth = () => useContext(MyContextData);

export const UserContext = (props: UserProps) => {
  const [user, SetUser] = useState<User>({} as any);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const fetchUser = () => {
      axios
        .get(apiUrl, { withCredentials: true })
        .then((response) => {
          if (response.data.authenticated) {
            console.log('response.data:', response.data);
            SetUser(response.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          //if 401 redirect to login...
          if (error.response.status === 401) {
            console.log('error.response.status === 401');
            setIsAuthenticated(false);
            login();
          }
          if (error.response) {
            console.log(
              'The request was made and the server responded with a status code that falls out of the range of 2xx'
            );
            console.log('resp data:', error.response.data);
            console.log('resp status:', error.response.status);
            console.log('resp heders :', error.response.headers);
          } else if (error.request) {
            console.log(
              'The request was made but no response was received :',
              error.request
            );
          } else {
            console.log(
              'Something happened in setting up the request that triggered an Error',
              error.message
            );
          }
          console.log(error.config);
        });
    };
    fetchUser();
  }, []);

  function login() {
    window.location.href = returnUrlByEnvironment;
  }

  async function logout() {
    try {
      await axios.get(logoutUrl, { withCredentials: true });
      window.location.href = window.location.origin;
    } catch (error) {
      console.log('callApiLogoutEndPoint error : ', error);
    }
  }

  return (
    <MyContextData.Provider
      value={{ user, isLoading, login, logout, isAuthenticated }}
    >
      {props.children}
    </MyContextData.Provider>
  );
};
