import { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import Button from "./Button";
import Sticker from "../assets/Badge.png";
import { FÖRETAGSKORT, MATKLUBBEN } from "../constants";
import { Customer } from "../types";
import { useAuth } from "../context/AuthContext";
import ShowAppleWalletButton from "./AddToWalletButton";
import { setBackground } from "../constants/setBackgrounds";

const Card = ({
  customerNumber,
  friendlyName,
  customerType,
  status,
}: Customer) => {
  const [src, setSrc] = useState<string>("");
  const imageRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  useEffect(() => {
    QRCode.toDataURL(customerNumber, {
      width: 400,
      margin: 2,
    })
      .then(setSrc)
      .catch((err) => {
        console.error(err);
      });
  }, [customerNumber]);

  const handleClickShare = async () => {
    const newFile = await fetch(src).then((r) => r.blob());

    if (!newFile) {
      return;
    }
    const data = {
      files: [
        new File([newFile], "image.png", {
          type: newFile.type,
        }),
      ],
      title: `En QR-kod har delats med dig från M&S Restaurangbutiker`,
      text: `Bifogat -  QR-kod för ${friendlyName} , kundnummer: ${customerNumber}`,
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share content (data)", data);
      }
      await navigator.share(data);
    } catch (err) {
      console.error("err :", err);
    }
  };

  const hideShareButton = (): boolean => {
    return customerType === MATKLUBBEN || customerType === FÖRETAGSKORT;
  };

  const cardIsInactivated: boolean = status !== 1 ? true : false;

  return (
    <>
      <div className={setBackground(customerType)}>
        <div className="card__body">
          <div className="card__infowrapper">
            <div className="card__heading">{customerType}</div>
            <div className="card__workplace">{friendlyName}</div>
            {customerType === FÖRETAGSKORT && (
              <div className="card__firstname__lastname">
                {user?.firstname} {user?.lastname}
              </div>
            )}
            <div className="card__customerno">Kundnr: {customerNumber}</div>
          </div>
          <div className="card__imagewrapper">
            <img
              src={src}
              alt="qr"
              className={
                `card__image${customerNumber}` +
                (cardIsInactivated ? " opacity" : "")
              }
            />
          </div>
          {cardIsInactivated ? (
            <div className="card__inactivatedwrapper">
              <img
                src={Sticker}
                alt="inactive-sticker"
                className="card__inactive-sticker"
              />
            </div>
          ) : null}
        </div>

        <div className="card__footer">
          <div className="card__buttonwrapper">
            {src ? (
              <>
                {!cardIsInactivated && (
                  <ShowAppleWalletButton
                    customerNumber={customerNumber}
                    friendlyName={friendlyName}
                    customerType={customerType}
                    firstName={user?.firstname as string}
                    lastName={user?.lastname as string}
                  />
                )}
                {!hideShareButton() ? (
                  <Button
                    className={"share__button"}
                    onClick={handleClickShare}
                    border={"none"}
                    color={"black"}
                    textcolor={"white"}
                    radius={"7px"}
                    width={"102px"}
                    height={"34px"}
                    disabled={cardIsInactivated ? true : false}
                  >
                    Dela koden
                  </Button>
                ) : null}
                <div ref={imageRef}></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
