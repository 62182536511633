import { User } from "../types";

const UserNotRegistered = (data: User) => {
  return (
      <div className="user">
          <div className="user__info">
              <div className="user__name">Hej {data?.firstname}!</div>
              <p>
                  Du är för närvarande inte medlem i M&S Restaurangbutiker
              </p>
              <p style={{marginTop: 15}}>
                  <strong><a href="https://www.martinserverarestaurangbutiker.se/bli-kund/">Klicka här för att bli medlem</a></strong>
              </p>
          </div>
      </div>
  );
};

export default UserNotRegistered;
