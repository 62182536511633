import axios from 'axios';
import { appleWalletapiUrl } from '../constants/urlConstants';
import { userWalletProps } from '../types';

export const handleClickDownloadPass = async (data: userWalletProps) => {
  console.log(
    '🚀 ~ file: walletService.ts:7 ~ handleClickDownloadPass ~ data:',
    data
  );

  try {
    const res = await axios.post(appleWalletapiUrl, data, {
      headers: {
        Accept: 'application/vnd.apple.pkpass',
      },
      responseType: 'arraybuffer',
      timeout: 30000,
      withCredentials: true,
    });

    console.log('res.data', res.data);
    const file = new Blob([res.data], {
      type: 'application/vnd.apple.pkpass',
    });

    console.log('file:', file);
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.download = `${data.customerNumber}.pkpass`;
    link.href = fileURL;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (e: any) {
    console.log(
      '🚀 ~ file: walletService.ts:61 ~ handleClickDownloadPass ~ e:',
      e.data
    );
  }
};
