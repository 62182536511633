import React from "react";
import { buttonProps } from "../types";

const Button: React.FC<buttonProps> = ({
  border,
  color,
  textcolor,
  children,
  height,
  onClick,
  radius,
  width,
  disabled,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={className}
      style={{
        backgroundColor: color,
        color: textcolor,
        border,
        borderRadius: radius,
        height,
        width,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
