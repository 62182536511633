import React from 'react';

const ErrorPage = () => {
  return (
    <section className="error">
      <h1>Något gick fel</h1>
      <p>
        Tjänsten som do försöker använda har drabbats av ett tillfälligt fel.
      </p>
      <h3>Kontakta oss</h3>
      <p>
        Har du andra frågor eller funderingar,
        <a href="https://www.martinservera.se/kontakt-och-hjalp/kontakta-oss">
          kontakta oss gärna
        </a>
      </p>
    </section>
  );
};

export default ErrorPage;
