import { User } from "../types";

const Userinfo = (data: User) => {
  const nrOfCards: number | undefined = data?.customers?.length;
  let infoText: string = "Ditt digitala kundkort";

  if (nrOfCards && nrOfCards > 1) {
    infoText = "Dina digitala kundkort";
  }
  return (
    <div className="user">
      <div className="user__info">
        <div className="user__name">Hej {data?.firstname}!</div>
        <div className="user__message">{infoText}</div>
      </div>
    </div>
  );
};

export default Userinfo;
