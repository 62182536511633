import LinkMailto from "../components/LinkMailto";
import { User } from "../types";

const Myprofilepage = (data: User) => {
  return (
    <section className="myprofile">
      <div className="myprofile__wrapper">
        <h1 className="myprofile_heading font--bold">Min Profil</h1>
        <div className="info__wrapper">
          <label>Förnamn</label>
          <div className="myprofile__firstname">{data?.firstname}</div>
        </div>
        <div className="info__wrapper">
          <label>Efternamn</label>
          <div className="myprofile__lastname">{data?.lastname}</div>
        </div>
        <div className="info__wrapper">
          <label>E-mail</label>
          <div>{data?.emailAddress}</div>
        </div>
        <div className="myprofile__infotext">
          <b>Behöver du hjälp, kontakta</b>
          <p>
            <LinkMailto
              label="butiker@martinservera.se"
              mailto="mailto:butiker@martinservera.se"
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Myprofilepage;
