import { Link } from "react-router-dom";

type mailtoProps = {
  mailto: string;
  label: string;
};

const LinkMailto = (mail: mailtoProps) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mail.mailto;
        e.preventDefault();
      }}
    >
      {mail.label}
    </Link>
  );
};

export default LinkMailto;
