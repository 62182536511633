import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button';
import InstallPwa from '../components/InstallPwa';
import { ReactComponent as HeaderLogo } from '../assets/headerlogo.svg';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showMask, setShowMask] = useState<boolean>(false);
  const { logout } = useAuth();

  useEffect(() => {
    if (window.innerWidth < 1080) {
      setShowMask(true);
    } else if (window.innerWidth > 1080) {
      setShowMask(false);
    }
  }, []);

  return (
    <header className="header">
      <div className="header__wrapper">
        <span className="nav__hamburger">
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => setShowMenu(!showMenu)}
          />
        </span>

        <Link to="/" className="header__logolink">
          <HeaderLogo />
        </Link>
        <InstallPwa />
      </div>
      <nav className="nav">
        <div
          className={`menumask ${showMenu && !showMask ? 'visible' : ''}`}
          onClick={() => setShowMenu(false)}
        ></div>

        <div className={`menu ${showMenu ? 'visible' : ''}`}>
          <div className="menu__visible_toprow">
            <span
              className="menu__close"
              onClick={() => setShowMenu(false)}
            ></span>
            <HeaderLogo />
          </div>

          <ul className="menu__list">
            <li className="meny__listitem">
              <Link to="/" onClick={() => setShowMenu(false)}>
                <span> Mina kort</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
            <li className="meny__listitem">
              <Link
                className="meny__listitemlink"
                to="/min-profil"
                onClick={() => setShowMenu(false)}
              >
                <span>Min profil</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          </ul>

          <div className="showmenu__logoutbutton">
            <Button
              className={'showmenu__logoutbutton_btn'}
              onClick={async () => await logout()}
              border="none"
              color="black"
              textcolor="white"
              radius="7px"
              width="100px"
              height="40px"
              children="Logga ut"
            ></Button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
