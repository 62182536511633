import Card from "./Card";
import { User } from "../types";

const setOwerflow = (slides: any) => {
  if (slides > 1) {
    return "slides";
  } else return "slides noOverflowX";
};

const CardContainer = (user: User) => {
  return (
    <div className="slider">
      <div className={setOwerflow(user?.customers?.length)}>
        {user?.customers?.map((customer: any) => (
          <Card
            customerNumber={customer.customerNumber}
            key={customer.customerNumber}
            friendlyName={customer.friendlyName}
            customerType={customer.customerType}
            status={customer.status}
          />
        ))}
      </div>
    </div>
  );
};
export default CardContainer;
