import CardContainer from "../components/CardContainer";
import Userinfo from "../components/Userinfo";
import {User} from "../types";
import UserNotRegistered from "../components/UserNotRegistered";

const Mainpage = (data: User) => {
    return (
        <section className="main">
            {data.customers?.length ?
                <><Userinfo {...data} />
                    <CardContainer {...data} /></> : 
                <UserNotRegistered  {...data} />}

        </section>
    );
};

export default Mainpage;
