import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <section>
      <div className="myprofile__wrapper">
        Sidan existerar inte
        <Link to="/hem" className="link-home">
          Hem
        </Link>
      </div>
    </section>
  );
};

export default LandingPage;
