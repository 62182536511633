import React from "react";

const NotFoundPage = () => {
  return (
    <section className="notfound">
      <h1>Sidan kan inte hittas</h1>
      <p>
        Sidan du försökte nå kunde tyvärr inte hittas. Här hittar du några
        möjliga förklaringar till detta.
      </p>
      <h3>Fel adress</h3>
      <p>
        Kontrollera att du skrev in rätt adress och försök igen. Om det inte
        hjälper kan du gå till förstasidan eller webbplatskartan och försöka
        hitta sidan den vägen.
      </p>
      <h3>Kontakta oss</h3>
      <p>
        Har du andra frågor eller funderingar,
        <a href="https://www.martinservera.se/kontakt-och-hjalp/kontakta-oss">
          kontakta oss gärna
        </a>
      </p>
    </section>
  );
};

export default NotFoundPage;
