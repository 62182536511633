import { FÖRETAGSKORT, KUNDKORT, MATKLUBBEN } from ".";

export const setBackground = (customerType:string): string => {
    switch (customerType) {
      case MATKLUBBEN:
        return "card card--red";
      case FÖRETAGSKORT:
        return "card card--blue";
      case KUNDKORT:
        return "card card--green";
      default:
        return "card card--blue";
    }
  };