import { ReactComponent as ReactLogo } from "../assets/add_to_Wallet_SE.svg";
import {
  isChrome,
  isIOS,
  isMacOs,
  isMobileSafari,
  isSafari,
} from "react-device-detect";
import { userWalletProps } from "../types";
import { handleClickDownloadPass } from "../wallet/walletService";

const ShowAppleWalletButton = (data: userWalletProps) => {
  if (isIOS || isSafari || isMobileSafari || isMacOs) {
    return <ReactLogo onClick={() => handleClickDownloadPass(data)} />;
  }
  return null;
};

export default ShowAppleWalletButton;
