import './App.css';
import Header from './Header';
import Mainpage from '../pages/Mainpage';
import { Navigate, Route, Routes } from 'react-router-dom';
import Myprofilepage from '../pages/Myprofilepage';
import NotFoundPage from '../pages/Notfoundpage';
import Spinner from '../components/Spinner';
import { useAuth } from '../context/AuthContext';
import LandingPage from '../pages/LandingPage';
import ErrorPage from '../pages/ErrorPage';

// import Footer from "./Footer";

function App() {
  const { user, isLoading, login, isAuthenticated } = useAuth();

  if (!isAuthenticated) return login();

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className="App">
        <>
          <Header />
          <div className="app__container">
            <Routes>
              <Route path="/hem" element={<Mainpage {...user} />} />
              <Route
                path="/"
                element={
                  isAuthenticated ? <Navigate to="/hem" /> : <LandingPage />
                }
              />
              <Route path="/min-profil" element={<Myprofilepage {...user} />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          {/* <Footer /> */}
        </>
      </div>
    </>
  );
}

export default App;
