import { useAddToHomescreenPrompt } from "../hooks/useAddToHomescreenPrompt";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const InstallPwa = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);

  const hide = () => setVisibleState(false);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  if (!isVisible) {
    return <div />;
  }

  return (
    <div onClick={hide} className="install__pwa">
      <FontAwesomeIcon icon={faDownload} onClick={promptToInstall} />
    </div>
  );
};

export default InstallPwa;
